<template>
	<div>
		<v-row align="start" class="my-2" no-gutters
			:key="index"
			v-for="(interaction, index) in interactions.slice().reverse()">
			<v-col cols="2" :order="isMyInteraction(interaction.user) ? 'first':'last'" :class="isMyInteraction(interaction.user) ?  'text-left':'text-right'"
				v-if="!$vuetify.breakpoint.mobile">
				<img :src="interaction.user.info.avatar" class="user-avatar"/>
			</v-col>
			<v-col cols="12" sm="10">
				<div class="interaction-container"
					:class="setClassInteraction(interaction)">
					<v-icon :color="interaction.is_response ? '#CAE7CC':'#E5E9F2'" class="icon-arrow">{{isMyInteraction(interaction.user) ? 'mdi-menu-left':'mdi-menu-right'}}</v-icon>
					<div class="d-flex flex-row justify-space-between">
						<div>
							<p class="mb-0 text-primary font-weight-bold" v-if="interaction.is_response">Respuesta Oficial</p>
							<div class="d-flex align-center">
								<img :src="interaction.user.info.avatar" class="user-avatar mini mr-2" v-if="$vuetify.breakpoint.mobile"/>
								<div>
									<p class="mb-0 text-primary" :class="$vuetify.breakpoint.mobile ? 'caption':''">{{interaction.user.name}}</p>
									<div class="text-primary caption" v-if="$vuetify.breakpoint.mobile">
										<small>{{interaction.created_at | toDateTime}}</small>
									</div>
								</div>
							</div>
							<div class="d-flex flex-row align-center mt-1">
								<p class="mb-0 text-primary caption">Visto por:</p>
								<div class="d-flex flex-row align-center text-primary caption mx-2" v-for="(user, index) in interaction.seen_by" :key="index">
									<img :src="user.avatar" class="user-avatar small mr-1"/> {{user.name}}
								</div>
							</div>
						</div>
						<div class="text-right text-primary caption" v-if="!$vuetify.breakpoint.mobile">
							{{interaction.created_at | toDateTime}}
						</div>
					</div>
					<div class="my-3">
						<div v-html="$options.filters.highligthSearch(interaction.message, searchText)"></div>
						<MediaGallery
							v-if="interaction.attachments.filter(item => item.file.pathmedia).length > 0"
							:images="interaction.attachments.filter(item => item.file.pathmedia).map(item => item.file.pathmedia)" />
					</div>
					<div class="my-3" v-if="interaction.attachments.filter(item => !item.file.pathmedia).map(item => !item.file.pathmedia).length > 0">
						<div class="d-flex align-center mb-1">
							<p class="mb-0">{{interaction.attachments.length}} Documentos Adjuntos:</p>
							<v-btn
								v-if="interaction.attachments.length > 1"
								class="mx-2 my-1"
								rounded
								color="#2D4B95"
								dark
								x-small
								:loading="loadingFilePath"
								@click="() => downloadAttachments(interaction.attachments)"
							>
								<v-icon dark small>mdi-paperclip</v-icon>
								<p class="mb-0 ml-2">Descargar Todo</p>
							</v-btn>
						</div>
						<div
							v-for="(attachment, index) in interaction.attachments.filter(item => !item.pathmedia)"
							:key="index">
							<div class="d-flex flex-row align-center px-1">
								<p class="mb-0 text-primary mr-2" style="min-width: 60px">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												class="mx-1 my-1"
												color="#2D4B95"
												dark
												x-small
												icon
												:loading="loadingFilePath"
												v-bind="attrs"
												v-on="on"
												@click="getFilesUrl(attachment, true)"
											>
												<v-icon >mdi-open-in-new</v-icon>
											</v-btn>
										</template>
										<span>Visualizar en navegador</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												class="mx-1 my-1"
												color="#5E85EF"
												dark
												x-small
												icon
												:loading="loadingFilePath"
												v-bind="attrs"
												v-on="on"
												@click="getFilesUrl(attachment, true, true)"
											>
												<v-icon>mdi-cloud-download-outline</v-icon>
											</v-btn>
										</template>
										<span>Descargar archivo</span>
									</v-tooltip>
								</p>
								<p class="mb-0 caption">{{attachment.file.name}}</p>
							</div>
							<v-divider v-if="index < interaction.attachments.filter(item => !item.pathmedia).length - 1"></v-divider>
						</div>
						<!-- <div class="d-flex flex-row">
							<v-btn
								class="mx-2 my-1"
								rounded
								color="#5E85EF"
								dark
								small
								:loading="loadingFilePath"
								@click="getFilesUrl(interaction.attachments)"
							>
								<v-icon dark small>mdi-file-document-outline</v-icon>
								<p class="mb-0 ml-2">Descarga</p>
							</v-btn>
							<v-btn
								class="mx-2 my-1"
								rounded
								color="#3EBFA3"
								dark
								small
								@click="() => console.log('Click')"
							>
								<v-icon dark small>mdi-magnify</v-icon>
								<p class="mb-0 ml-2">Ver Documento</p>
							</v-btn>
							<v-btn
								class="mx-2 my-1"
								color="#5E85EF"
								small
								text
								v-if="interaction.attachments.length > 1"
								@click="() => showMore = !showMore"
							>
								<p class="mb-0 ml-2">Ver Más</p>
							</v-btn>
						</div> -->
						<div v-if="showMore">
							<v-divider class="my-3"></v-divider>
							<div class="bottom-file-container">
								<div class="d-flex flex-row"
									:key="index"
									v-for="(attachment, index) in interaction.attachments">
									<v-btn
										class="mx-2 my-1"
										color="#5E85EF"
										dark
										small
										text
										:loading="loadingFilePath"
										@click="getFilesUrl(attachment, true)"
									>
										<v-icon dark color="#2D4B95">mdi-file-document-outline</v-icon>
										<p class="mb-0">{{attachment.file.name}}</p>
									</v-btn>
								</div>
							</div>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {getPublicUrl} from '@/helpers/api/tasks'
import {getZippedFiles} from '@/helpers/api/fileUploads'
import MediaGallery from '@/components/Globals/Helpers/MediaGallery'

export default {
	name: 'Interaction',
	components: { MediaGallery },
  props: {
    interactions: {
      type: Array,
      default: () => []
    },
		searchText: {
			type: String,
			default: ''
		}
  },
	data: () => ({
		loadingFilePath: false,
		showMore: false
	}),
	methods: {
    setClassInteraction(interaction){
      let final_class = this.isMyInteraction(interaction.user) ? 'right':'left'
      final_class += interaction.is_response ? ' response':''
      return final_class
    },
    isMyInteraction(interactionUser){
      return interactionUser.id == this.$store.userStore.state.userInfo.id
    },
    async downloadAttachments(attachments){
      this.loadingFilePath = true
			const toZip = attachments.map(item => item.file)
      const files = await getZippedFiles({files_to_zip: toZip})
      if(files.code == 200){
          // this.downloadFile(files.fileurl, 'archivo.zip')
          window.open(files.fileurl);
      }
      this.loadingFilePath = false
    },
    async getFilesUrl(attachments, singleFile = false, download = false){
      if(singleFile){ attachments = [attachments] }
      this.loadingFilePath = true
      const files = await getPublicUrl({attachments, force_download: download})
      if(files.code === 200){
        files.files.forEach((file) => {
          window.open(file.url);
        })
      }
      this.loadingFilePath = false
    },
	}
}
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .interaction-container.response{
    background-color: #CAE7CC;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  .icon-top{
    padding: 2px;
    margin: 0 2px;
    border-radius: 100%;
  }
  .icon-top.high{
    background-color: #F4516C;
  }
  .icon-top.medium{
    background-color: #fb8c00;
  }
  .icon-top.normal{
    background-color: #3EBFA3;
  }
  .icon-top.primary{
    background-color: #2D4B95;
  }
  .icon-top.light-blue, .bg-light-blue{
    background-color: #5E85EF;
  }
  .parent-subtareas{
    display: flex;
    flex-wrap: wrap;
  }
  .parent-subtareas .subtareas-checkbox{
    flex: 1 0 30%;
  }
  .notification-img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: contain;
  }
  .button-preview-img{
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
	/deep/ .is_response_interaction .tiptap-vuetify-editor__content{
		border: 2px solid #3ebfa3;
	}
	.is_response_interaction_bottom_text{
		color: #3ebfa3;
	}
	/deep/ span.highlight{
		background-color: #8dfff4;
	}
</style>