<template>
	<v-container fluid id="consultas-container" class="d-flex flex-column">
		<v-btn-toggle style="width: 100%;" v-if="!$vuetify.breakpoint.mobile">
			<v-btn class="custom-button-consultas"
				:class="this.$store.consultasStore.state.selectedType === 1 ? 'selected' : ''" tile
				:disabled="!checkPermissions(['create_tasks'])"
				@click="$store.consultasStore.commit('setCreateTareaModal', true)">
				<v-icon>mdi-notebook-plus-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Nueva Tarea</p>
			</v-btn>
			<v-btn class="custom-button-consultas"
				:class="this.$store.consultasStore.state.selectedType === 2 ? 'selected' : ''" tile @click="changeListing(2)">
				<v-icon>mdi-file-document-multiple-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Mis Tareas</p>
			</v-btn>
			<v-btn class="custom-button-consultas"
				:class="this.$store.consultasStore.state.selectedType === 3 ? 'selected' : ''" tile @click="changeListing(3)">
				<v-icon>mdi-view-list-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Pendientes</p>
			</v-btn>
			<v-btn class="custom-button-consultas"
				:class="this.$store.consultasStore.state.selectedType === 4 ? 'selected' : ''" tile @click="changeListing(4)">
				<v-icon>mdi-text-box-check-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Finalizadas</p>
			</v-btn>
			<v-btn class="custom-button-consultas"
				:class="this.$store.consultasStore.state.selectedType === 5 ? 'selected' : ''" tile @click="changeListing(5)">
				<v-icon>mdi-account-group-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Pendientes Por Usuario</p>
			</v-btn>
			<!-- <v-btn class="custom-button-consultas" :class="selected === 5 ? 'selected':''" tile
				@click="selected = 5">
				<v-icon>mdi-account-group-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Por Usuario</p>
			</v-btn> -->
			<div class="d-flex align-center justify-end flex-grow-1 px-3">
				<v-btn rounded small color="#AAAAAA" :disabled="loadingExport" @click="excelExport()">
					<div class="d-flex align-center" v-if="loadingExport">
						<v-progress-circular indeterminate color="white" :width="2" :size="15"></v-progress-circular>
						<p class="mb-0 ml-2 white--text">Preparando Excel</p>
					</div>
					<div class="d-flex align-center" v-else>
						<v-icon color="white">mdi-microsoft-excel</v-icon>
						<p class="mb-0 ml-2 white--text">Exportar a Excel</p>
					</div>
				</v-btn>
			</div>
		</v-btn-toggle>
		<v-menu offset-y v-else>
			<template v-slot:activator="{ on, attrs }">
				<v-btn rounded color="#2D4B95" dark small block v-bind="attrs" v-on="on">
					Seleccione Acción
				</v-btn>
			</template>
			<v-list>
				<v-list-item>
					<v-btn class="custom-button-consultas" block
						:class="this.$store.consultasStore.state.selectedType === 1 ? 'selected' : ''" tile
						:disabled="!checkPermissions(['create_tasks'])"
						@click="$store.consultasStore.commit('setCreateTareaModal', true)">
						<v-icon>mdi-notebook-plus-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Nueva Tarea</p>
					</v-btn>
				</v-list-item>
				<v-list-item>
					<v-btn class="custom-button-consultas" block
						:class="this.$store.consultasStore.state.selectedType === 2 ? 'selected' : ''" tile
						@click="changeListing(2)">
						<v-icon>mdi-file-document-multiple-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Mis Tareas</p>
					</v-btn>
				</v-list-item>
				<v-list-item>
					<v-btn class="custom-button-consultas" block
						:class="this.$store.consultasStore.state.selectedType === 3 ? 'selected' : ''" tile
						@click="changeListing(3)">
						<v-icon>mdi-view-list-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Pendientes</p>
					</v-btn>
				</v-list-item>
				<v-list-item>
					<v-btn class="custom-button-consultas" block
						:class="this.$store.consultasStore.state.selectedType === 4 ? 'selected' : ''" tile
						@click="changeListing(4)">
						<v-icon>mdi-text-box-check-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Tareas Finalizadas</p>
					</v-btn>
				</v-list-item>
				<v-list-item>
					<v-btn class="custom-button-consultas" block
						:class="this.$store.consultasStore.state.selectedType === 5 ? 'selected' : ''" tile
						@click="changeListing(5)">
						<v-icon>mdi-account-group-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Pendientes Por Usuario</p>
					</v-btn>
				</v-list-item>
			</v-list>
		</v-menu>
		<MisTareas v-if="this.$store.consultasStore.state.selectedType === 2" />
		<ListadoConsultas ref="listadoConsultas"
			v-if="(this.$store.consultasStore.state.selectedType === 3 || this.$store.consultasStore.state.selectedType === 4) && !$vuetify.breakpoint.mobile"
			:ended="this.$store.consultasStore.state.selectedType === 4" />
		<ListadoConsultasMobile ref="listadoConsultas"
			v-if="(this.$store.consultasStore.state.selectedType === 3 || this.$store.consultasStore.state.selectedType === 4) && $vuetify.breakpoint.mobile"
			:ended="this.$store.consultasStore.state.selectedType === 4" />
		<TareasByUser v-if="this.$store.consultasStore.state.selectedType === 5" />
	</v-container>
</template>

<script>
import MisTareas from './MisTareas'
import ListadoConsultas from './ListadoConsultas'
import ListadoConsultasMobile from './ListadoConsultasMobile'
import TareasByUser from './TareasByUser'
import { exportTasksByProject } from '@/helpers/api/exports'
// import {list} from '@/helpers/api/tasks'
export default {
	name: 'ConsultasContainer',
	components: { MisTareas, ListadoConsultas, ListadoConsultasMobile, TareasByUser },
	data: () => ({
		createTareaModal: false,
		loadingExport: false
	}),
	mounted() {
		this.getConsultasList()
	},
	methods: {
		changeListing(selected) {
			if (this.$store.consultasStore.state.selectedType != selected) {
				this.$store.consultasStore.commit('setSelectedType', selected)
				this.getConsultasList()
				this.$refs.listadoConsultas && this.$refs.listadoConsultas.clearFilters()
			}
		},
		async getConsultasList() {
			let project_id = null
			if (this.$store.projectsStore.state.selectedProject) {
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else {
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if (project_id) {
				this.$store.consultasStore.dispatch('getMyTasks', { project_id })
			}
		},
		async excelExport() {
			this.loadingExport = true
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id
			}
			const exportRdi = await exportTasksByProject(data)
			if (exportRdi.code == 200) {
				window.open(exportRdi.url);
			}
			this.loadingExport = false
		}
	}
};
</script>

<style scoped>
.text-primary {
	color: #2D4B95;
}

.custom-button-consultas.v-btn {
	background-color: white;
	color: #2D4B95;
	opacity: 1 !important;
}

/deep/ .custom-button-consultas.v-btn .v-icon,
/deep/ .custom-button-consultas.v-btn p {
	color: #2D4B95 !important;
	font-size: 13px;
}

/deep/ .custom-button-consultas.v-btn .v-icon {
	font-size: 20px;
}

/deep/ .custom-button-consultas.v-btn:hover .v-icon,
/deep/ .custom-button-consultas.v-btn:hover p,
/deep/ .custom-button-consultas.v-btn.selected .v-icon,
/deep/ .custom-button-consultas.v-btn.selected p {
	color: white !important;
	;
}

.custom-button-consultas.selected,
.custom-button-consultas:hover {
	background-color: #2D4B95 !important
}

.sticky-button-container {
	position: sticky;
	top: 0;
	background-color: white;
	/* Adjust as needed */
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	/* Add shadow */
	z-index: 1;
}
</style>