<template>
	<v-container fluid>
		<v-row align="start">
			<v-col cols="12" sm="3">
				<v-card
				>
					<v-img
						height="200"
						v-if="$store.projectsStore.state.selectedProject"
						:src="$store.projectsStore.state.selectedProject.project_image"
					>
						<v-icon dark class="icon-general-project-status">mdi-information-outline</v-icon>
					</v-img>
					<v-card-text class="pa-0">
						<v-divider></v-divider>
						<div class="my-2 px-2">
							<p class="mb-0 font-weight-bold text-primary">
								<v-icon small color="#2D4B95" class="mr-1">mdi-file-cog-outline</v-icon> Almacenamiento Utilizado:
							</p>
							<v-skeleton-loader type="list-item" v-if="$store.fileManagerStore.state.loadingStorageInfo"></v-skeleton-loader>
							<v-progress-linear
								v-else
								:value="$store.fileManagerStore.state.projectStorageInfo.total_size*100/10737418240"
								class="my-1"
								rounded
								color="#2D4B95"
								background-color="#787878"
								height="18"
							>
								<template v-slot:default>
									<p class="white--text mb-0 font-weight-bold">{{$store.fileManagerStore.state.projectStorageInfo.total_size | toDataSize}}<small>/10 GB</small></p>
								</template>
							</v-progress-linear>
						</div>
						<div v-if="editingProjectBoard">
							<tiptap-vuetify
								v-model="projectStatus"
								:extensions="extensions"
								required
								id="edit-project-status"
							/>
							<div class="d-flex flex-row justify-space-between my-2">
								<v-btn
									class="mx-2 my-1"
									rounded
									color="white"
									small
									v-if="checkPermissions(['end_rdi'])"
									:loading="loading"
									@click="editingProjectBoard = false"
								>
									<p class="mb-0 text-primary">Cancelar</p>
								</v-btn>
								<v-btn
									class="mx-2 my-1"
									rounded
									color="#5E85EF"
									dark
									small
									v-if="checkPermissions(['end_rdi'])"
									:loading="loading"
									@click="updateBoard()"
								>
									<p class="mb-0">Actualizar</p>
								</v-btn>
							</div>
						</div>
						<div v-else>
							<v-divider></v-divider>
							<div class="d-flex flex-row justify-end">
								<v-btn text class="pa-0" style="height: auto;"
									v-if="checkCompanyLevelPermissions(['edit_project'])"
									@click="editProjectBoard()">
									<v-icon color="#5e85ef" small class="mr-1">mdi-note-edit</v-icon>
									<p class="mb-0 caption text-primary text-capitalize">Editar</p>
								</v-btn>
							</div>
							<div
								class="pa-2 status-board-info"
								v-if="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject.status_board"
								v-html="$store.projectsStore.state.selectedProject.status_board.status_board">
							</div>
						</div>
					</v-card-text>

					<!-- <v-card-text>
						<v-btn
							color="#EB4964"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/gestiondocumentos.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Gestión de Documentos</p>
							</div>
						</v-btn>
						<v-btn
							color="#32B99E"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/programacompras.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Programa de Compras</p>
							</div>
						</v-btn>
						<v-btn
							color="#E29E38"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/subcontrato.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Programa de Sub-contratos</p>
							</div>
						</v-btn>
						<v-btn
							color="#2D51DC"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/bodega.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Bodega</p>
							</div>
						</v-btn>
						<v-btn
							color="#E6E277"
							dark
							block
							class="py-2 my-2 button-project-detail-card"
						>
							<div class="d-flex align-center" style="width: 100%;">
								<img :src="require('@/assets/Svg/HomeProjectIcons/programa.svg')" style="width: 16px;"/>
								<p style="font-size: 10px;" class="mb-0 ml-2 font-weight-bold">Programación</p>
							</div>
						</v-btn>
					</v-card-text> -->
				</v-card>
			</v-col>
			<v-col cols="12" sm="9">
				<v-row>
					<v-col cols="12" sm="6">
						<ProjectDetailCardConsultas :info="$store.projectsStore.state.selectedConsultasInfo"/>
					</v-col>
					<v-col cols="12" sm="6">
						<ProjectDetailCardRDI :info="$store.projectsStore.state.selectedRdiInfo"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<ProjectDetailCardAdicionales :info="$store.projectsStore.state.selectedAdicionalesInfo"/>
					</v-col>
					<v-col cols="12" sm="6">
						<ProjectDetailCardAvance :info="$store.projectsStore.state.selectedAvancesInfo"/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row align="start">
			<v-col cols="12">
				<ProjectListItem v-for="(project, index) in $store.projectsStore.state.projectsList" :info="project" :projectIndex="index" :key="index"/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link,
	Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import ProjectDetailCardConsultas from './ProjectDetailCardConsultas'
import ProjectDetailCardAvance from './ProjectDetailCardAvance'
import ProjectDetailCardAdicionales from './ProjectDetailCardAdicionales'
import ProjectDetailCardRDI from './ProjectDetailCardRDI'
import ProjectListItem from '@/components/Views/Projects/ProjectListItem'
import Chart from "chart.js"
import {updateProjectBoard} from '@/helpers/api/project'

export default {
	name: 'ProjectDetail',
	components: {ProjectDetailCardConsultas, ProjectDetailCardAvance, ProjectDetailCardAdicionales, ProjectDetailCardRDI, ProjectListItem, TiptapVuetify},
	data: () => ({
		loading: false,
		projectStatus: '',
		editingProjectBoard: false,
    extensions: [
      History,
			// Table,
			// TableCell,
			// TableHeader,
			// TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
	}),
	mounted(){
		this.textCenter()
	},
	methods: {
		editProjectBoard(){
			if(this.$store.projectsStore.state.selectedProject.status_board){
				this.projectStatus = this.$store.projectsStore.state.selectedProject.status_board.status_board
			}
			this.editingProjectBoard = true
		},
		async updateBoard(){
			this.loading = true
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				status_board: this.projectStatus,
			}
			const board = await updateProjectBoard(data)
			if(board.code == 200){
				let project = {...this.$store.projectsStore.state.selectedProject}
				project.status_board = board.status
				this.$store.projectsStore.commit('setSelectedProject', project)
			}
			this.editingProjectBoard = false
			this.loading = false
		},
		textCenter(){
			Chart.pluginService.register({
				beforeDraw: function(chart) {
					var width = chart.chart.width;
					var height = chart.chart.height;
					var ctx = chart.chart.ctx;

					ctx.restore();
					var fontSize = (height / 50).toFixed(2);
					ctx.font = fontSize + "em sans-serif";
					ctx.textBaseline = "middle";

					let text = ''
					if(chart.config.options.elements && chart.config.options.elements.center && chart.config.options.elements.center.text){
						text = chart.config.options.elements.center.text
					}
					var textX = Math.round((width - ctx.measureText(text).width) / 2);
					var textY = height / 1.8;

					ctx.fillText(text, textX, textY);
					ctx.save();
				}
			});
		}
	}
};
</script>

<style>
  .text-primary{
    color: #2D4B95;
  }
	.button-project-detail-card{
		border-radius: 8px;
		position: relative;
		z-index: 1;
		height: auto!important;
	}
	.button-project-detail-card:after{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(0deg, rgba(2,0,36,0.45) 0%, rgba(0,0,0,0) 100%);
		z-index: 2;
	}
	.button-project-detail-card span.v-btn__content{z-index: 3;}
	.icon-general-project-status{
		position: absolute;
		top: 5px;
		left: 5px;
		padding: 2px;
		background-color: #34BFA3;
		border-radius: 100%;
		border: 2px solid white;
	}
	#edit-project-status .tiptap-vuetify-editor__content{
		min-height: 150px;
		padding: 0;
	}
	#edit-project-status .tiptap-vuetify-editor__content .ProseMirror{
		padding: 15px;
    margin: 0!important;
    height: 100%;
    min-height: 150px;
	}
	#edit-project-status .tiptap-vuetify-editor__content .ProseMirror p{
		margin-top: 0!important;
		margin-bottom: 0!important;
	}
	.status-board-info p{
		margin-bottom: 0;
	}
	.status-board-info table{
		width: 100%;
		border-collapse: collapse;
		margin: 5px 0px;
	}
	.status-board-info table td{
		border: 1px solid grey;
		padding: 4px;
	}
</style>
