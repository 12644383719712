import {endpoints} from './endpoints'
import request from '../request'
// import stores from '../../stores/index.js'

export function create(data, onUploadProgress = null) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createTask,
			method: 'post',
			data,
			onUploadProgress
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateTask(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateTask,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function list(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listTasks,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function listByUser(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listTasksByUser,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function listMyTasks(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listMyTasks,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function nextCreatedTasks(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.nextCreatedTasks,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function nextPendingTasks(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.nextPendingTasks,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function nextEndedTasks(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.nextEndedTasks,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getTareaByID(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getTareaByID,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function addInteraction(data, onUploadProgress = null) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addInteraction,
			method: 'post',
			data,
			onUploadProgress
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getPublicUrl(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getPublicUrl,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function closeTask(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.closeTask,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function changeResponsable(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.changeResponsable,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function changeVisualizationStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.changeVisualizationStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function markTaskAsRead(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.markTaskAsRead,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function downloadPDF(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.downloadPDFTask,
			method: 'post',
			data,
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function sendTaskMail(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendTaskMail,
			method: 'post',
			data,
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function deleteTask(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteTask,
			method: 'post',
			data,
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function addParticipant(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addParticipant,
			method: 'post',
			data,
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function removeParticipant(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeParticipant,
			method: 'post',
			data,
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

// function setTareas(tareas){
// 	const mistareas = tareas.my_tasks
// 	const listadotareas = tareas.tasks
// 	stores.consultasStore.commit('setMisTareasInfo', {
// 		status: 1,
// 		count_creadas: mistareas.filter(item => item.status === 1).length,
// 		data_creadas: mistareas.filter(item => item.status === 1),
// 		count_pendientes: mistareas.filter(item => item.status === 2).length,
// 		data_pendientes: mistareas.filter(item => item.status === 2),
// 		count_finalizadas: mistareas.filter(item => item.status === 3).length,
// 		data_finalizadas: mistareas.filter(item => item.status === 3),
// 	})

// 	stores.consultasStore.commit('setListadoTareas', {
// 		status: 1,
// 		count: listadotareas.length,
// 		data: listadotareas
// 	})
// }