<template>
  <div class="text-center" ref="createTareaRef" id="text-test">
    <v-dialog
      v-model="modalStatus"
      max-width="800"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title"
									:class="$vuetify.breakpoint.mobile ? 'body-2':''">
										Nuevo Adicional
										<v-progress-circular v-if="loadingLastAdditionalInfo" indeterminate size="20" width="3" color="#2D4B95"></v-progress-circular>
										<span v-else-if="lastAdditionalInfo && lastAdditionalInfo.number">- Nº {{lastAdditionalInfo.number + 1}}</span>
								</p>
              </div>
							<div class="d-flex flex-row align-center">
								<v-btn class="mx-2" fab dark x-small color="#fb8c00" @click="minimizeForm">
									<v-icon dark>mdi-window-minimize</v-icon>
								</v-btn>
								<v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModalSelf()">
									<v-icon dark>mdi-close</v-icon>
								</v-btn>
							</div>
            </div>
            <v-row align="center" class="mt-2">
              <v-col cols="12">
                <p class="mb-0 body-2"><b class="text-primary">El adicional será emitido a tu nombre:</b> {{$store.userStore.state.userInfo.name}}</p>
								<p class="mb-0 body-2"><b class="text-primary">En el proyecto:</b> {{$store.projectsStore.state.selectedProject.name}}</p>
              </v-col>
            </v-row>
            <v-form ref="form" v-model="validform">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Adicional</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
											<v-btn
												dark
												x-small
												color="#5E85EF"
												class="mx-1"
												:outlined="newAdicionalForm.type === 1"
												@click="() => newAdicionalForm.type = 1"
											>
												<p class="mb-0">Obra Extraordinaria</p>
											</v-btn>
											<v-btn
												dark
												x-small
												color="#5E85EF"
												class="mx-1"
												:outlined="newAdicionalForm.type === 2"
												@click="() => newAdicionalForm.type = 2"
											>
												<p class="mb-0">Aumento de Obra</p>
											</v-btn>
											<v-btn
												dark
												x-small
												color="#5E85EF"
												class="mx-1"
												:outlined="newAdicionalForm.type === 3"
												@click="() => newAdicionalForm.type = 3"
											>
												<p class="mb-0">Disminución de Obra</p>
											</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Código</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
											<v-text-field
												v-model="newAdicionalForm.code"
												label="Código"
												placeholder="Ingrese código para el Adicional"
												:hint="lastCode"
												persistent-hint
												dense
												outlined
												required
												:rules="requiredRule"
											>
												<template v-slot:message="{message}">
													<span v-html="message"></span>
												</template>
											</v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row align="center">
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Especialidades</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <div class="d-flex flex-row">
                        <v-select
                          v-model="especialidades"
                          :items="$store.projectsStore.state.selectedProject.specialties"
                          item-text="name"
                          item-value="id"
                          label="Seleccione Especialidades"
                          single-line
                          hint="Selecciona las especialidades para el adicional"
                          :rules="requiredRule"
                          persistent-hint
                          hide-details
                          multiple
                          outlined
                          dense
                          required
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
							<v-row>
								<v-col cols="12">
									<v-row>
										<v-col cols="12" sm="3">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold"
														v-bind="attrs"
														v-on="on">Recordatorio</h2>
												</template>
												<span>Se agregará este Adicional a tus recordatorios semanales</span>
											</v-tooltip>
										</v-col>
										<v-col cols="12" sm="9">
											<div>
												<v-btn
													v-for="(day, index) in weekDays"
													:key="index"
													dark
													x-small
													color="#5E85EF"
													:outlined="!isWeekDaySelected(index)"
													class="mx-1"
													@click="addWeekDay(index)"
												>
													<p class="mb-0">{{day}}</p>
												</v-btn>
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Enviar Copia</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
											<v-combobox
												v-model="copy_to"
												:loading="$store.usersStore.state.loadingUserList"
												:items="this.$store.usersStore.state.usersList"
												item-text="name"
												item-value="id"
												single-line
												outlined
												dense
												required
												multiple
												label="Enviar copia por correo"
												hint="Se enviará una copia en PDF del Adicional a los usuarios que indiques aquí. Puedes ingresar un correo que no esté en el listado, escríbelo y presiona enter."
												persistent-hint
												return-object
												taggable
											>
												<template v-slot:item="data">
													<v-list-item-avatar>
														<img v-if="data.item.info && data.item.info.avatar" :src="data.item.info.avatar">
														<v-icon v-else>mdi-account</v-icon>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title>{{data.item.name}} <span class="light-blue-text">{{data.item.projectjob.userjobtype.name}}</span></v-list-item-title>
														<v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
													</v-list-item-content>
												</template>
											</v-combobox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div class="d-flex flex-row align-center my-6">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title body-1">Costos Asociados</p>
              </div>
              <v-row>
                <v-col cols="4">
									<h2 class="ml-2 mb-0 body-1 font-weight-bold">Costo Directo</h2>
									<div class="d-flex flex-row">
										<v-text-field
											v-model="newAdicionalForm.direct_cost"
											placeholder="Costo Directo"
											type="number"
											outlined
											dense
											rounded
											class="custom-input-numbers text-center"
											:rules="[...requiredRule,...decimalsRule]"
										>
										</v-text-field>
									</div>
                </v-col>
                <v-col cols="4">
									<h2 class="ml-2 mb-0 body-1 font-weight-bold">Gastos Generales</h2>
									<div class="d-flex flex-row">
										<v-text-field
											v-model="newAdicionalForm.general_expenses"
											placeholder="Gastos Generales"
											type="number"
											outlined
											dense
											rounded
											class="custom-input-numbers text-center"
											:rules="[...requiredRule,...decimalsRule]"
										>
										</v-text-field>
									</div>
                </v-col>
                <v-col cols="4">
									<h2 class="ml-2 mb-0 body-1 font-weight-bold">Utilidad</h2>
									<div class="d-flex flex-row">
										<v-text-field
											v-model="newAdicionalForm.utility"
											placeholder="Utilidad"
											type="number"
											outlined
											dense
											rounded
											class="custom-input-numbers text-center"
											:rules="[...requiredRule,...decimalsRule]"
										>
										</v-text-field>
									</div>
                </v-col>
                <v-col cols="8">
									<h2 class="ml-2 mb-0 body-1 font-weight-bold">Moneda</h2>
									<div class="d-flex flex-row">
										<v-select
											v-model="newAdicionalForm.currency_id"
											:items="$store.generalStore.state.systemCurrencies.currencies"
											:loading="$store.generalStore.state.systemCurrencies.loading"
											item-text="name"
											item-value="id"
											label="Seleccione Moneda"
											single-line
											hint="Selecciona la moneda para el adicional"
											:rules="requiredRule"
											persistent-hint
											hide-details
											outlined
											dense
											required
										></v-select>
									</div>
                </v-col>
                <v-col cols="4">
									<h2 class="ml-2 mb-0 body-1 font-weight-bold">Total Solicitado</h2>
									<div class="d-flex flex-row">
										<v-text-field
											:value="computedTotalRequest"
											placeholder="Total Solicitado"
											outlined
											dense
											rounded
											readonly
											class="custom-input-numbers text-center"
										>
										</v-text-field>
									</div>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="newAdicionalForm.title"
                    label="Título"
                    placeholder="Ingrese título para el Adicional"
                    outlined
                    required
                    hide-details
                    :rules="requiredRule"
										@keydown="sendToSearch"
                  >
										<template slot="append">
											<v-progress-circular
												v-if="searching"
												:size="20"
												:width="2"
												color="#2D4B95"
												indeterminate
											></v-progress-circular>
											<v-chip
												v-else-if="searchResultsCount > 0"
												dark
												small
												color="#5E85EF"
												@click="showResults = true">
												<v-avatar
													left
													class="primary darken-4"
												>
													{{searchResultsCount}}
												</v-avatar>
												<p class="mb-0">Elementos similares</p>
												<v-icon>mdi-chevron-down</v-icon>
											</v-chip>
										</template>
									</v-text-field>
									<SearchBlockForms v-if="showResults" :searching="searching" :searchResult="searchResult" :closeResults="closeResults"/>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <h2 class="ml-2 mb-2 body-1 font-weight-bold">Descripción</h2>
                  <tiptap-vuetify
                    v-model="newAdicionalForm.description"
                    :extensions="extensions"
                    required
                    :rules="requiredRule"
                    id="create-tarea-text-editor"
                  />
                  <div class="my-2 d-flex flex-row" style="width: 100%">
                    <div class="d-flex flex-row justify-space-between" style="width: 100%">
                      <div class="d-flex flex-row align-center flex-wrap" style="flex: 1;">
                        <div v-for="(file,index) in files" :key="index" class="ma-1" style="flex: 1 0 45%;">
                          <div v-if="filesPreview[index] && filesPreview[index].src" class="preview-img-container">
                            <v-btn
                              icon
                              color="#5E85EF"
                              x-small
                              class="button-preview-img"
                              @click="removeAttachment(index)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <img :src="filesPreview[index].src" class="preview-img"/>
                          </div>
                          <div v-else class="d-flex flex-row align-center justify-start">
                            <v-btn
                              icon
                              color="#5E85EF"
                              x-small
                              @click="removeAttachment(index)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            {{file.name}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-0">
                <v-col cols="12" sm="6" v-if="!$vuetify.breakpoint.mobile">
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="d-flex flex-row justify-end" style="width: 100%">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													class="mx-2 my-1"
													color="#2D4B95"
													text
													v-bind="attrs"
													v-on="on"
													:disabled="disabledAttachSelector"
													@click="() => attachFiles = !attachFiles"
												>
													<v-icon small class="mr-1">{{attachFiles ? 'mdi-check-circle-outline':'mdi-checkbox-blank-circle-outline'}}</v-icon>
													<p class="mb-0 text-primary text-capitalize caption">Enviar adjuntos</p>
												</v-btn>
											</template>
											<span>Se adjuntarán los archivos en el correo de notificación</span>
										</v-tooltip>
                    <div class="d-flex justify-space-between" style="width: 100%" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
                      <v-btn
                        class="mx-2 my-1"
                        rounded
                        color="white"
                        raised
                        @click="onButtonClick"
                      >
                        <p class="mb-0 text-primary">Adjuntar Archivo</p>
                      </v-btn>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        @change="onFileChanged"
                      >
                      <v-btn
                        class="mx-2 my-1"
                        rounded
                        color="#2D4B95"
                        raised
                        :loading="loadingData"
                        @click="() => preCreateAdditional()"
                      >
                        <p class="mb-0 white--text">{{confirmButtonText}}</p>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link,
	Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
// import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import _debounce from 'lodash/debounce'
import {create, getLastAdditionalData} from '@/helpers/api/additionals'
import {search} from '@/helpers/api/general'
import SearchBlockForms from '@/components/Globals/Headers/SearchBlockForms'

export default {
  name: 'CreateAdditionalModal',
  components: { TiptapVuetify, SearchBlockForms },
  props: {
    modalStatus:{
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  watch: {
    searchUsers (val) {

      if(val.length > 5){
        // this.searchUsersBD(val)
        this.searchUsersLocal(val)
      }
    },
		files () {
			this.checkFilesAttach()
		}
  },
	computed: {
		computedTotalRequest(){
			return this.$options.filters.toThousandFilter(parseFloat(this.newAdicionalForm.direct_cost) + parseFloat(this.newAdicionalForm.general_expenses) + parseFloat(this.newAdicionalForm.utility))
			// return (parseFloat(this.newAdicionalForm.direct_cost) + parseFloat(this.newAdicionalForm.general_expenses) + parseFloat(this.newAdicionalForm.utility)).toFixed(2)
		},
		lastCode(){
			let code = 'Sin Información'
			if(this.loadingLastAdditionalInfo){
				code= 'Cargando'
			}
			if(this.lastAdditionalInfo){
				code = '<b>' + this.lastAdditionalInfo.code + '</b>'
			}
			return 'Último código generado: ' + code
		}
	},
  data: () => ({
    validform: true,
    newAdicionalForm: {
      title: '',
      type: 1,
			code: null,
      description: '',
      especialidades: null,
      files: [],
      copy_to: null,
			currency_id: 1,
			direct_cost: 0,
			general_expenses: 0,
			utility: 0,
			total_solicitado: 0,
			reminder: [],
			reminder_repeatable: true
    },
		attachFiles: false,
		disabledAttachSelector: true,
    loadingSubjects: false,
    projectSubjects: [{name: 'Test', id: 1}, {name: 'Testito', id: 2}],
    especialidades: [],
    copy_to: [],
    datePicker: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
		decimalsRule: [
			v => v.toString().match(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/) || 'El monto puede tener 2 decimales, formato XXXXXX.XX'
		],
    newTarea: '',
    subtareas: [],
    files: [],
    filesPreview: [],
    extensions: [
      History,
			// Table,
			// TableCell,
			// TableHeader,
			// TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    content: '',
    loadingData: false,
    searchUsers: null,
		showResults: false,
		searchResultsCount: 0,
		searching: false,
		searchResult: {},
		cancelTimer: 7,
		inCancelTime: false,
		cancelInterval: null,
		confirmButtonText: 'Confirmar',
		weekDays: ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo'],
		loadingLastAdditionalInfo: false,
		lastAdditionalInfo: null
  }),
	beforeDestroy(){

	},
  mounted(){
    setTimeout(() => {
      this.pasteListener()
    }, 1000);
    if(this.$store.projectsStore.state.projectSubjects.length == 0){
      this.getProjectSubjects()
    }
    if(this.$store.usersStore.state.usersList.length === 0){
      this.getUsersList()
    }
		this.newAdicionalForm.currency_id = this.$store.projectsStore.state.selectedProject.details.currency
    if(this.$store.rdiStore.state.initialDataRDI){
      this.newAdicionalForm = {
				title: '',
				type: 1,
				description: '',
				especialidades: null,
				files: [],
				copy_to: null,
				currency_id: 1,
				direct_cost: 0,
				general_expenses: 0,
				utility: 0,
				total_solicitado: 0,
				reminder: [],
				reminder_repeatable: true
			}
      this.$store.rdiStore.commit('setInitialDataRDI', null)
    }
		this.getLastAdditionalInfo()
  },
  methods: {
		async getLastAdditionalInfo(){
			this.loadingLastAdditionalInfo = true
			try{
				const lastInfo = await getLastAdditionalData({project_id: this.$store.projectsStore.state.selectedProject.id})
				if(lastInfo.code == 200){
					this.lastAdditionalInfo = lastInfo.last_additional
					this.setSugggestedCode()
				}
			}
			catch(e){
        const datas = {
					message: 'Hubo un error al obtener la información del último adicional creado. De todas formas podrás crearlo sin problemas.',
					title: 'Error',
					created: new Date(),
					type: 'warning',
					icon: 'mdi-alert-circle',
					color: '#fb8c00'
        }
        this.$store.globalStore.dispatch('addMessage', datas)
			}
			this.loadingLastAdditionalInfo = false
		},
		setSugggestedCode(){
			if(this.lastAdditionalInfo && this.lastAdditionalInfo.code){
				const numbers = this.lastAdditionalInfo.code.match(/\d+$/)
				if( numbers[0] && /^-?[\d.]+(?:e-?\d+)?$/.test(numbers[0]) ){
					const lastCharInt = parseInt(numbers)
					this.newAdicionalForm.code = numbers.index > 0 ? this.lastAdditionalInfo.code.slice(0, -1) + ( lastCharInt + 1 ):lastCharInt + 1
				}
			}
		},
		closeResults(){
			this.showResults = false
			this.searching = false
		},
    sendToSearch: _debounce(function(){ this.searchForString(this.newAdicionalForm.title) }, 800),
		async searchForString(searchString){
			this.searching = true
			const data = {
				search: searchString,
				project_id: this.$store.projectsStore.state.selectedProject.id,
				exclude_description: true
			}
			const searchResult = await search(data)
			if(searchResult.code == 200){
				this.searchResult = searchResult
				this.searchResultsCount = searchResult.tasks.length + searchResult.rdis.length
			}
			this.searching = false
		},
		addWeekDay(index){
			const found = this.newAdicionalForm.reminder.findIndex((itemReminder) => itemReminder === index)
			if(found !== -1){
				this.newAdicionalForm.reminder.splice(found, 1)
			}
			else{
				this.newAdicionalForm.reminder.push(index)
			}
			if(this.newAdicionalForm.reminder.length === 0){
				this.newAdicionalForm.reminder_repeatable = false
			}
		},
		isWeekDaySelected(index){
			return this.newAdicionalForm.reminder.includes(index)
		},
		minimizeForm(){
			let title = 'Borrador RDI: '
			if(this.newAdicionalForm.title && this.newAdicionalForm.title != ''){
				title += this.newAdicionalForm.title
			}
			else{
				title += 'Sin Título'
			}
      const data = {
        task_id: uuidv4(),
        title,
        type: 'RDI',
        type_id: 4,
        number: null,
				newAdicionalForm: this.newAdicionalForm
      }
      this.$store.globalStore.commit('addTaskToTasksHandler', data)
      this.closeModalSelf()
    },
		closeModalSelf(){
			this.closeModal()
			this.newAdicionalForm = {
				title: '',
				type: 1,
				description: '',
				especialidades: null,
				files: [],
				copy_to: null,
				currency_id: 1,
				direct_cost: 0,
				general_expenses: 0,
				utility: 0,
				total_solicitado: 0,
				reminder: [],
				reminder_repeatable: true
			}
		},
    pasteListener(){
      const textEditor = document.querySelector('#create-tarea-text-editor .ProseMirror')
      if(textEditor){
        textEditor.addEventListener('paste', async (event) => {
          const dT = event.clipboardData || window.clipboardData
          const file = dT.files[0]
          if(file){
            this.files.push(file)
            if(file.type.includes('image')){
              const base64File = await this.toBase64(file)
              this.filesPreview.push({src: base64File})
            }
            else{
              this.filesPreview.push({noImage: true})
            }
          }
        })
      }
    },
    async getProjectSubjects(){
      this.$store.projectsStore.dispatch('getProjectSubjects', this.$store.projectsStore.state.selectedProject.id)
    },
    async getUsersList(){
      const data = {
        project_id: this.$store.projectsStore.state.selectedProject.id,
        start: 0,
        limit: 20
      }
      this.$store.usersStore.dispatch('getUsersList', data)
    },
    searchUsersLocal(searchString){
      this.loadingUsers = true
      const search = this.$store.usersStore.state.usersList
      this.usersToSelect = search.filter((user) =>{
        return user.name.includes(searchString) || user.email.includes(searchString) 
      })
      this.loadingUsers = false
    },
    usersFilter(item, queryText) {
      const name = item.name.toLowerCase()
      const email = item.email.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 || email.indexOf(searchText) > -1
    },
    async getAdditionalList(){
      let project_id = null
      if(this.$store.projectsStore.state.selectedProject){
        project_id = this.$store.projectsStore.state.selectedProject.id
      }
      else{
        const id = localStorage.getItem('selectedProjectID')
        project_id = parseInt(id)
      }
      if(project_id){
        this.$store.adicionalesStore.dispatch('getAdditionals', {project_id})
      }
    },
    getColorStatus(){
      if(this.$store.consultasStore.state.currentTarea.status === 1){
        return '#3EBFA3'
      }
      return '#F4516C'
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    async onFileChanged(e) {
      const newFile = e.target.files[0]
      this.files.push(newFile)
      if(newFile.type.includes('image')){
        const base64File = await this.toBase64(newFile)
        this.filesPreview.push({src: base64File})
      }
      else{
        this.filesPreview.push({noImage: true})
      }
    },
    removeAttachment(index){
      this.files.splice(index, 1)
      this.filesPreview.splice(index, 1)
    },
		checkFilesAttach(){
			if(this.files.length > 0){
				this.disabledAttachSelector = false
			}
			else{
				this.disabledAttachSelector = true
				this.attachFiles = false
			}
		},
    toBase64(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result)
        };
        reader.onerror = function () {
          resolve(null)
        };
      })
    },
    preCreateAdditional(){
			console.log('newAdicionalForm: ', this.newAdicionalForm)
			if(this.inCancelTime){
				clearInterval(this.cancelInterval)
				this.confirmButtonText = 'Confirmar'
				this.inCancelTime = false
				return null
			}
      if(this.newAdicionalForm.description === '' || !this.newAdicionalForm.description){
        const datas = {
         message: 'Por favor escribe una descripción. Este campo no puede estar vacio.',
         title: 'Error',
          created: new Date(),
          type: 'success',
          icon: 'mdi-alert-circle',
          color: '#fb8c00'
        }
        this.$store.globalStore.dispatch('addMessage', datas)
        return false
      }
      if(this.$refs.form.validate()){
				this.inCancelTime = true
				this.cancelTimer = 7
				this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
				this.cancelInterval = setInterval(() => {
					this.cancelTimer--
					this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
					if(this.cancelTimer == 0){
						this.inCancelTime = false
						clearInterval(this.cancelInterval)
						this.createAdditional()
					}
				}, 1000);
      }
    },
    async createAdditional(){
			this.loadingData = true
			this.confirmButtonText = 'Confirmar'
			if(this.especialidades.length > 0){
				this.newAdicionalForm.especialidades = JSON.stringify(this.especialidades)
			}
			if(this.copy_to.length > 0){
				const emails_copy = this.copy_to.map((email) => {
					let emailData = null
					if(email.email){
						emailData = {email: email.email, name: email.name}
					}
					else{
						emailData = {email, name: ''}
					}
					return emailData
				})
				this.newAdicionalForm.copy_to = JSON.stringify(emails_copy)
			}

			const hasReminders = this.newAdicionalForm.reminder.length > 0
			this.newAdicionalForm.reminder = JSON.stringify(this.newAdicionalForm.reminder)
			this.newAdicionalForm.project_id = this.$store.projectsStore.state.selectedProject.id
			const formData = new FormData()
			Object.keys(this.newAdicionalForm).forEach((key) => {
				if(this.newAdicionalForm[key]){
					formData.append(key, this.newAdicionalForm[key])
				}
			})
			const biggerFiles = []
			this.files.forEach((file) => {
				biggerFiles.push({
					type: 5,
					payload: null,
					description: this.newAdicionalForm.title,
					total: file.size,
					uuid: uuidv4(),
					file,
					attach_files: this.attachFiles,
					copy_to: this.newAdicionalForm.copy_to
				})
			})
			const additional = await create(formData)
			if(additional.code === 200){
				if(biggerFiles.length > 0){
					biggerFiles.map(file => {
						file.item_id = additional.additional.id
						file.payload = {
							additional_id: additional.additional.id,
							additional_revision_id: additional.additional_revision.id,
							project_id: this.$store.projectsStore.state.selectedProject.id
						}
						this.$store.globalStore.commit('setFilesToUploadCenter', file)
					})
				}
				await this.getAdditionalList()
				this.$store.projectsStore.dispatch('getDashboardInfo')
				this.closeModalSelf()
				this.setMessage(additional.additional, biggerFiles.length > 0)
				hasReminders && this.$store.notificationsStore.dispatch('getReminders')
			}
			this.loadingData = false
    },
		checkReloadTask(task){
			if(this.$store.consultasStore.state.currentTarea && this.$store.consultasStore.state.currentTarea.id == task.id){
        this.$store.consultasStore.commit('setCurrentTarea', task)
			}
		},
    setMessage(additional, filesToUC = false){
      let message = 'Haz click aquí para visualizarla.'
			if(filesToUC){
				message += ' Hay algunos archivos que se están cargando.'
			}
      const data = {
        message,
        title: 'Se ha creado el Adicional ' + additional.code,
        created: new Date(),
        type: 'success',
        icon: 'mdi-check-circle',
        color: '#3EBFA3',
        action: 'additional',
        task_id: additional.id
      }
      this.$store.globalStore.dispatch('addMessage', data)
    },
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #2D4B95;
  }
  .info-icon{
    background-color: #2D4B95;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #5E85EF;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  .date-limit-input{
    max-width: 250px;
  }
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: contain;
  }
  .button-preview-img{
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
  /deep/ .remove-bottom-details .v-text-field__details{display: none;}
  /deep/ .remove-bottom-details .v-input__slot{margin-bottom: 0;}
/*  /deep/ .text-editor.tiptap-vuetify-editor .ProseMirror{
    margin: 0!important;
  }*/
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
	/deep/ .custom-input-numbers input::-webkit-outer-spin-button,
	/deep/ .custom-input-numbers input::-webkit-inner-spin-button {
			/* display: none; <- Crashes Chrome on hover */
			-webkit-appearance: none;
			margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
	}

	/deep/ .custom-input-numbers input[type=number] {
			-moz-appearance:textfield; /* Firefox */
	}
</style>