import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/Pages/Login'
import ResetPassRequest from '../components/Pages/ResetPassRequest'
import ResetPass from '../components/Pages/ResetPass'
import NewUserPassword from '../components/Pages/NewUserPassword'

import Home from '../components/Views/Home/Dashboard'
import ProjectsIndex from '../components/Views/Projects/ProjectsIndex'
import ClosedProjectsIndex from '../components/Views/Projects/ClosedProjectsIndex'
import UsersIndex from '../components/Views/Users/Index'
import ConsultasIndex from '../components/Views/Consultas/ConsultasIndex'
import userStore from '../stores/UserStore'
import RdiIndex from '../components/Views/Rdi/RdiIndex'
import AdicionalesIndex from '../components/Views/Adicionales/AdicionalesIndex'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history', // https://router.vuejs.org/api/#mode
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes(){
	return [
    {
      path: '/newuser',
      name: 'NewUserPassword',
      meta: { label: 'NewUserPassword'},
      component: NewUserPassword
    },
    {
      path: '/login',
      name: 'Login',
      meta: { label: 'Login'},
      component: Login
    },
    {
      path: '/reset-pass-request',
      name: 'ResetPassRequest',
      meta: { label: 'ResetPassRequest'},
      component: ResetPassRequest
    },
    {
      path: '/reset-pass/:token',
      name: 'ResetPass',
      meta: { label: 'ResetPass'},
      component: ResetPass
    },
    {
      path: '/',
      name: 'Dashboard',
      meta: { label: 'Panel de Control'},
      component: Home
    },
    {
      path: '/projects',
      name: 'Proyectos',
      meta: { label: 'Proyectos'},
      component: ProjectsIndex
    },
    {
			path: '/closed-projects',
			name: 'ClosedProjects',
      meta: { label: 'Proyectos Cerrados'},
			component: ClosedProjectsIndex
    },
    {
      path: '/users',
      name: 'Usuarios',
      meta: { label: 'Usuarios'},
      component: UsersIndex
    },
    {
      path: '/consultas',
      name: 'Consultas',
      meta: { label: 'Consultas'},
      component: ConsultasIndex
    },
    {
      path: '/rdi',
      name: 'Rdi',
      meta: { label: 'Rdi'},
      component: RdiIndex
    },
    {
      path: '/adicionales',
      name: 'Adicionales',
      meta: { label: 'Adicionales'},
      component: AdicionalesIndex
    },
	]
}

router.beforeEach((to, from, next) => {
	console.log('to.name', to.name)
  if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'ResetPassRequest' && to.name !== 'ResetPass' && to.name !== 'NewUserPassword' && !userStore.state.isAuthenticated) next({ name: 'Login' })
  else if ((to.name === 'Login' || to.name === 'Register' || to.name === 'NewUserPassword' || to.name === 'ResetPassRequest' || to.name === 'ResetPass') && userStore.state.isAuthenticated) next({ name: 'Dashboard' })
  else next()
})

export default router