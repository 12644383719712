import { render, staticRenderFns } from "./MediaGallery.vue?vue&type=template&id=46cd89d2&scoped=true"
import script from "./MediaGallery.vue?vue&type=script&lang=js"
export * from "./MediaGallery.vue?vue&type=script&lang=js"
import style0 from "./MediaGallery.vue?vue&type=style&index=0&id=46cd89d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46cd89d2",
  null
  
)

export default component.exports