<template>
	<div class="text-center" ref="createTareaRef" id="text-test">
		<v-dialog
			v-model="modalStatus"
			max-width="800"
			scrollable
			persistent
		>
			<v-card>
				<v-card-text class="pa-0">
					<div class="py-4 px-8">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-center">
								<v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
								<p class="mb-0 font-weight-bold text-primary title"
									:class="$vuetify.breakpoint.mobile ? 'body-2':''">Crear Consulta</p>
							</div>
							<div class="d-flex flex-row align-center">
								<v-btn class="mx-2" fab dark x-small color="#fb8c00" @click="minimizeForm">
									<v-icon dark>mdi-window-minimize</v-icon>
								</v-btn>
								<v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModalSelf()">
									<v-icon dark>mdi-close</v-icon>
								</v-btn>
							</div>
						</div>
						<v-row align="center" class="mt-2">
							<v-col cols="12">
								<p class="mb-0 body-2"><b class="text-primary">La consulta será emitida a tu nombre:</b> {{$store.userStore.state.userInfo.name}}</p>
								<p class="mb-0 body-2"><b class="text-primary">En el proyecto:</b> {{$store.projectsStore.state.selectedProject.name}}</p>
							</v-col>
						</v-row>
						<v-form ref="form" v-model="validform">
							<v-row align="center">
								<v-col cols="12">
									<v-text-field
										v-model="newTaskForm.title"
										label="Título"
										placeholder="Ingrese título de la consulta"
										outlined
										required
										hide-details
										:rules="requiredRule"
										@keyup="sendToSearch"
									>
										<template slot="append">
											<v-progress-circular
												v-if="searching"
												:size="20"
												:width="2"
												color="#2D4B95"
												indeterminate
											></v-progress-circular>
											
										</template>
									</v-text-field>
									<div class="custom-chip">
									<v-chip
												v-if="searchResultsCount > 0"
												dark
												small
												color="#5E85EF"
												@click="showResults = true">
												<v-avatar
													left
													class="primary darken-4"
												>
													{{searchResultsCount}}
												</v-avatar>
												<p class="mb-0">Elementos similares</p>
												<v-icon>mdi-chevron-down</v-icon>
											</v-chip>
										</div>
									<SearchBlockForms v-if="showResults" :searching="searching" :searchResult="searchResult" :closeResults="closeResults"/>
								</v-col>
							</v-row>
							<v-row align="center" class="mt-0">
								<v-col cols="12">
									<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Descripción</h2>
									<tiptap-vuetify
										v-model="newTaskForm.description"
										:extensions="extensions"
										required
										:rules="requiredRule"
										id="create-tarea-text-editor"
									/>
									<div class="my-2 d-flex flex-row" style="width: 100%">
										<div class="d-flex justify-space-between" style="width: 100%" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
											<div class="d-flex flex-row align-center flex-wrap" style="flex: 1;">
												<div v-for="(file,index) in files" :key="index" class="ma-1" style="flex: 1 0 45%;">
													<div v-if="filesPreview[index] && filesPreview[index].src" class="preview-img-container">
														<v-btn
															icon
															color="#5E85EF"
															x-small
															class="button-preview-img"
															@click="removeAttachment(index)"
														>
															<v-icon>mdi-close</v-icon>
														</v-btn>
														<img :src="filesPreview[index].src" class="preview-img"/>
													</div>
													<div v-else class="d-flex flex-row align-center justify-start">
														<v-btn
															icon
															color="#5E85EF"
															x-small
															@click="removeAttachment(index)"
														>
															<v-icon>mdi-close</v-icon>
														</v-btn>
														{{file.name}}
													</div>
												</div>
											</div>
											<v-btn
												class="mx-2 my-1"
												rounded
												color="white"
												raised
												@click="onButtonClick"
											>
												<p class="mb-0 text-primary">Adjuntar Archivo</p>
											</v-btn>
											<input
												ref="uploader"
												class="d-none"
												type="file"
												@change="onFileChanged"
											>
										</div>
									</div>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<v-row align="center">
										<v-col cols="12" sm="3">
											<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Prioridad</h2>
										</v-col>
										<v-col cols="12" sm="9">
											<div class="d-flex" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
												<v-btn
													class="mx-2 my-1"
													color="#F4516C"
													dark
													small
													:outlined="newTaskForm.priority === 3"
													@click="() => newTaskForm.priority = 3"
												>
													<p class="mb-0">! Inmediata</p>
												</v-btn>
												<v-btn
													class="mx-2 my-1"
													color="warning"
													dark
													small
													:outlined="newTaskForm.priority === 2"
													@click="() => newTaskForm.priority = 2"
												>
													<p class="mb-0">! Media</p>
												</v-btn>
												<v-btn
													class="mx-2 my-1"
													color="#3EBFA3"
													dark
													small
													:outlined="newTaskForm.priority === 1"
													@click="() => newTaskForm.priority = 1"
												>
													<p class="mb-0">! Estándar</p>
												</v-btn>
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<v-row align="center">
										<v-col cols="12" sm="3">
											<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Fecha Crítica</h2>
										</v-col>
										<v-col cols="12" sm="9">
											<v-menu
												v-model="datePicker"
												:close-on-content-click="false"
												max-width="290"
											>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														:value="newTaskForm.critical_date"
														label="Fecha Crítica"
														prepend-inner-icon="mdi-calendar"
														readonly
														v-bind="attrs"
														v-on="on"
														outlined
														dense
														class="remove-bottom-details date-limit-input"
														@click:clear="date = null"
													></v-text-field>
												</template>
												<v-date-picker
													locale="es"
													v-model="newTaskForm.critical_date"
													@change="datePicker = false"
												></v-date-picker>
											</v-menu>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row align="start">
								<v-col cols="12">
									<v-row align="center">
										<v-col cols="12" sm="3">
											<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Asignar</h2>
										</v-col>
										<v-col cols="12" sm="9" class="py-0">
											<v-autocomplete
												v-model="newTaskForm.responsable"
												:loading="$store.usersStore.state.loadingUserList"
												:items="getFinalUserList()"
												:search-input.sync="searchUsers"
												item-text="name"
												item-value="id"
												item-disabled="false"
												:filter="usersFilter"
												single-line
												outlined
												dense
												required
												:rules="requiredRule"
												label="Seleccione Usuario Responsable"
												:hint="checkPermissions(['assign_responsable_task']) ? 'Ingresa al menos 5 caracteres para iniciar búsqueda de usuarios (por nombre o email)':'Puedes asignarte la tarea a ti o a un usuario Jefe OT'"
												persistent-hint
											>
												<template v-slot:item="data">
													<v-list-item-avatar>
														<img v-if="data.item.info && data.item.info.avatar" :src="data.item.info.avatar">
														<v-icon v-else>mdi-account</v-icon>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title>{{data.item.name}} <span class="light-blue-text">{{data.item.projectjob.userjobtype.name}}</span></v-list-item-title>
														<v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
													</v-list-item-content>
												</template>
											</v-autocomplete>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row align="start">
								<v-col cols="12">
									<v-row align="center">
										<v-col cols="12" sm="3">
											<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Participantes</h2>
										</v-col>
										<v-col cols="12" sm="9" class="py-0">
											<v-autocomplete
												v-model="participants"
												:loading="$store.usersStore.state.loadingUserList"
												:items="getFinalUserList(true)"
												:search-input.sync="searchUsersParticipants"
												item-text="name"
												item-value="id"
												item-disabled="false"
												:filter="usersFilter"
												single-line
												outlined
												dense
												required
												multiple
												:disabled="!newTaskForm.responsable"
												:rules="requiredRule"
												label="Seleccione Usuarios Participantes"
												hint="Primero asigna al usuario responsable"
												persistent-hint
											>
												<template v-slot:item="data">
													<v-list-item-avatar>
														<img v-if="data.item.info && data.item.info.avatar" :src="data.item.info.avatar">
														<v-icon v-else>mdi-account</v-icon>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title>{{data.item.name}} <span class="light-blue-text">{{data.item.projectjob.userjobtype.name}}</span></v-list-item-title>
														<v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
													</v-list-item-content>
												</template>
											</v-autocomplete>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-row>
										<v-col cols="12" sm="3">
											<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Enviar Copia</h2>
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														class="ml-2 mr-4 px-0"
														color="grey"
														text
														v-bind="attrs"
														v-on="on"
														:disabled="disabledAttachSelector"
														@click="() => attachFiles = !attachFiles"
													>
														<v-icon small class="mr-1">{{attachFiles ? 'mdi-check-circle-outline':'mdi-checkbox-blank-circle-outline'}}</v-icon>
														<p class="mb-0 text-capitalize caption">Enviar adjuntos</p>
													</v-btn>
												</template>
												<span>Se adjuntarán los archivos en el correo de notificación</span>
											</v-tooltip>
										</v-col>
										<v-col cols="12" sm="9">
											<v-combobox
												v-model="copy_to"
												:loading="$store.usersStore.state.loadingUserList"
												:items="getFinalUserList()"
												:search-input.sync="searchUsersCopyTo"
												item-text="name"
												item-value="id"
												single-line
												outlined
												dense
												required
												multiple
												label="Enviar copia por correo"
												hint="Se enviará una copia en PDF de la RDI a los usuarios que indiques aquí. Puedes ingresar un correo que no esté en el listado, escríbelo y presiona enter."
												persistent-hint
												return-object
												taggable
											>
												<template v-slot:item="data">
													<v-list-item-avatar>
														<img v-if="data.item.info && data.item.info.avatar" :src="data.item.info.avatar">
														<v-icon v-else>mdi-account</v-icon>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title>{{data.item.name}} <span class="light-blue-text">{{data.item.projectjob.userjobtype.name}}</span></v-list-item-title>
														<v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
													</v-list-item-content>
												</template>
											</v-combobox>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-row>
										<v-col cols="12" sm="3">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold"
														v-bind="attrs"
														v-on="on">Recordatorio</h2>
												</template>
												<span>Se agregará esta tarea a tus recordatorios semanales</span>
											</v-tooltip>
										</v-col>
										<v-col cols="12" sm="9">
											<div>
												<v-btn
													v-for="(day, index) in weekDays"
													:key="index"
													dark
													x-small
													color="#5E85EF"
													:outlined="!isWeekDaySelected(index)"
													class="mx-1"
													@click="addWeekDay(index)"
												>
													<p class="mb-0">{{day}}</p>
												</v-btn>
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Listado de Tareas</h2>
									<v-text-field
										v-model="newTarea"
										label="Tarea"
										placeholder="Escribe la descripción de la tarea y presiona enter o el botón"
										class="ma-2 pt-0 remove-bottom-details"
										@keydown.enter="addNewTarea()"
									>
										<template v-slot:append>
											<v-btn
												icon
												:disabled="!newTarea || newTarea === ''"
												color="#5E85EF"
												@click="addNewTarea()"
											>
												<v-icon>mdi-plus-circle</v-icon>
											</v-btn>
										</template>
									</v-text-field>
									<v-list-item-group>
											<v-list-item v-for="(subtarea, i) in subtareas" :key="i">
												<template v-slot:default>
													<v-list-item-action>
														<v-btn
															icon
															color="#F4516C"
															@click="removeNewTarea(i)"
														>
															<v-icon>mdi-close</v-icon>
														</v-btn>
													</v-list-item-action>
													<v-list-item-content>
														<v-list-item-title>{{ subtarea.title }}</v-list-item-title>
														<v-list-item-subtitle>Agregado: {{ subtarea.date_added }}</v-list-item-subtitle>
													</v-list-item-content>
												</template>
											</v-list-item>
										</v-list-item-group>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<div class="d-flex flex-row justify-end">
										<div class="d-flex flex-row">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														class="mx-2 my-1"
														color="#2D4B95"
														text
														v-bind="attrs"
														v-on="on"
														@click="() => newTaskForm.private = !newTaskForm.private"
													>
														<v-icon small class="mr-1">{{newTaskForm.private ? 'mdi-check-circle-outline':'mdi-checkbox-blank-circle-outline'}}</v-icon>
														<p class="mb-0 text-primary text-capitalize caption">Tarea Privada</p>
													</v-btn>
												</template>
												<span>Sólo podrá ser visualizada por ti y por la persona que asignes como responsable</span>
											</v-tooltip>
											<v-btn
												class="mx-2 my-1"
												rounded
												color="white"
												raised
												@click="closeModalSelf()"
											>
												<p class="mb-0 text-primary">Cancelar</p>
											</v-btn>
											<v-btn
												class="mx-2 my-1"
												rounded
												color="#2D4B95"
												raised
												:loading="loadingData"
												@click="() => preCreateTarea()"
											>
												<p class="mb-0 white--text">{{confirmButtonText}}</p>
											</v-btn>
										</div>
									</div>
								</v-col>
							</v-row>
						</v-form>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link,
	Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import moment from 'moment'
import _debounce from 'lodash/debounce'
import { v4 as uuidv4 } from 'uuid'
import {create} from '@/helpers/api/tasks'
import {searchUsersByEmail} from '@/helpers/api/project'
import {search} from '@/helpers/api/general'
import SearchBlockForms from '@/components/Globals/Headers/SearchBlockForms'

export default {
	name: 'CreateTareaModal',
	components: { TiptapVuetify, SearchBlockForms },
	props: {
		modalStatus:{
			type: Boolean,
			default: false
		},
		closeModal: {
			type: Function,
			required: true
		}
	},
	watch: {
		searchUsers (val) {
			if(val && val.length > 5){
				// this.searchUsersBD(val)
				this.searchUsersLocal(val)
			}
		},
		searchUsersParticipants (val) {
			if(val && val.length > 5){
				// this.searchUsersBD(val)
				this.searchUsersLocal(val)
			}
		},
		searchUsersCopyTo (val) {
			if(val && val.length > 5){
				// this.searchUsersBD(val)
				this.searchUsersLocal(val)
			}
		},
		files () {
			this.checkFilesAttach()
		}
	},
	data: () => ({
		validform: true,
		newTaskForm: {
			title: '',
			priority: 1,
			description: '',
			subtareas: [],
			participants: [],
			responsable: null,
			critical_date: null,
			files: [],
			private: false,
			copy_to: null,
			reminder: [],
			reminder_repeatable: true
		},
		disabledAttachSelector: true,
		attachFiles: false,
		datePicker: false,
		copy_to: [],
		requiredRule: [
			v => !!v || 'Campo requerido'
		],
		newTarea: '',
		subtareas: [],
		participants: [],
		files: [],
		filesPreview: [],
		extensions: [
			History,
			// Table,
			// TableCell,
			// TableHeader,
			// TableRow,
			Blockquote,
			Link,
			Underline,
			Strike,
			Italic,
			ListItem,
			BulletList,
			OrderedList,
			[Heading, {
				options: {
					levels: [1, 2, 3]
				}
			}],
			Bold,
			Code,
			HorizontalRule,
			Paragraph,
			HardBreak
		],
		content: '',
		loadingData: false,
		loadingUsers: false,
		usersToSelect: [],
		searchUsers: null,
		searchUsersParticipants: null,
		searchUsersCopyTo: null,
		showResults: false,
		searchResultsCount: 0,
		searching: false,
		searchResult: [],
		cancelTimer: 7,
		inCancelTime: false,
		cancelInterval: null,
		confirmButtonText: 'Confirmar',
		weekDays: ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo']
	}),
	mounted(){
		if(this.$store.consultasStore.state.borradorTarea){
			this.newTaskForm = this.$store.consultasStore.state.borradorTarea
			this.$store.consultasStore.commit('setBorradorTarea', null)
		}
		if(this.$store.usersStore.state.usersList.length === 0){
			this.getUsersList()
		}
		else if(!this.checkPermissions(['assign_responsable_task'])){
			this.newTaskForm.responsable = this.$store.userStore.state.userInfo.id
		}
		setTimeout(() => {
			this.pasteListener()
		}, 1000);
	},
	methods: {
		closeResults(){
			this.showResults = false
			this.searching = false
		},
		sendToSearch: _debounce(function(){ this.searchForString(this.newTaskForm.title) }, 800),
		async searchForString(searchString){
			this.searching = true
			const data = {
				search: searchString,
				project_id: this.$store.projectsStore.state.selectedProject.id,
				exclude_description: true
			}
			const searchResult = await search(data)
			if(searchResult.code == 200){
				this.searchResult = searchResult
				this.searchResultsCount = searchResult.tasks.length + searchResult.rdis.length
			}
			this.searching = false
		},
		addWeekDay(index){
			const found = this.newTaskForm.reminder.findIndex((itemReminder) => itemReminder === index)
			if(found !== -1){
				this.newTaskForm.reminder.splice(found, 1)
			}
			else{
				this.newTaskForm.reminder.push(index)
			}
			if(this.newTaskForm.reminder.length === 0){
				this.newTaskForm.reminder_repeatable = false
			}
		},
		isWeekDaySelected(index){
			return this.newTaskForm.reminder.includes(index)
		},
		minimizeForm(){
			let title = 'Borrador Tarea: '
			if(this.newTaskForm.title && this.newTaskForm.title != ''){
				title += this.newTaskForm.title
			}
			else{
				title += 'Sin Título'
			}
			const data = {
				task_id: uuidv4(),
				title,
				type: 'Tarea',
				type_id: 3,
				number: null,
				newTaskForm: this.newTaskForm
			}
			this.$store.globalStore.commit('addTaskToTasksHandler', data)
			this.closeModalSelf()
		},
		closeModalSelf(){
			this.closeModal()
			this.newTaskForm = {
				title: '',
				priority: 1,
				description: '',
				subtareas: [],
				participants: [],
				responsable: null,
				critical_date: null,
				files: [],
				private: false,
				copy_to: null,
				reminder: [],
				reminder_repeatable: true
			}
		},
		getFinalUserList(participants = false){
			if(this.checkPermissions(['assign_responsable_task'])){
				return participants ? this.$store.usersStore.state.usersList.filter((user) => user.id !== this.$store.userStore.state.userInfo.id && user.id !== this.newTaskForm.responsable):this.$store.usersStore.state.usersList
			}
			else{
				return this.$store.usersStore.state.usersList.filter((user) => {
					if(participants){
						return user.id !== this.newTaskForm.responsable
					}
					return user && ( ( user.projectjob && user.projectjob.job_type_id == 2 ) || user.id == this.$store.userStore.state.userInfo.id )
				})
			}
		},
		pasteListener(){
			const textEditor = document.querySelector('#create-tarea-text-editor .ProseMirror')
			if(textEditor){
				textEditor.addEventListener('paste', async (event) => {
					const dT = event.clipboardData || window.clipboardData
					const file = dT.files[0]
					if(file){
						this.files.push(file)
						if(file.type.includes('image')){
							const base64File = await this.toBase64(file)
							this.filesPreview.push({src: base64File})
						}
						else{
							this.filesPreview.push({noImage: true})
						}
					}
				})
			}
		},
		async getUsersList(){
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				start: 0,
				limit: 20
			}
			this.$store.usersStore.dispatch('getUsersList', data)
		},
		async getConsultasList(){
			let project_id = null
			if(this.$store.projectsStore.state.selectedProject){
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else{
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if(project_id){
				this.$store.consultasStore.dispatch('getMyTasks', {project_id})
			}
		},
		getColorStatus(){
			if(this.$store.consultasStore.state.currentTarea.status === 1){
				return '#3EBFA3'
			}
			return '#F4516C'
		},
		async searchUsersBD(email){
			this.loadingUsers = true
			const data = {
				email,
				project_id: this.$store.projectsStore.state.selectedProject.id
			}
			const users = await searchUsersByEmail(data)
			if(users.code === 200){
				this.usersToSelect = users.users
			}
			this.loadingUsers = false
		},
		searchUsersLocal(searchString){
			this.loadingUsers = true
			const search = this.$store.usersStore.state.usersList
			this.usersToSelect = search.filter((user) =>{
				return user.name.includes(searchString) || user.email.includes(searchString) 
			})
			this.loadingUsers = false
		},
		usersFilter(item, queryText) {
			const name = item.name.toLowerCase()
			const email = item.email.toLowerCase()
			const searchText = queryText.toLowerCase()

			return name.indexOf(searchText) > -1 || email.indexOf(searchText) > -1
		},
		addNewTarea(){
			this.subtareas.push({title: this.newTarea, date_added: moment().format('DD/MM/YYYY HH:mm')})
			this.newTarea = ''
		},
		onButtonClick() {
			this.$refs.uploader.click()
		},
		async onFileChanged(e) {
			const newFile = e.target.files[0]
			this.files.push(newFile)
			if(newFile.type.includes('image')){
				const base64File = await this.toBase64(newFile)
				this.filesPreview.push({src: base64File})
			}
			else{
				this.filesPreview.push({noImage: true})
			}
		},
		removeAttachment(index){
			this.files.splice(index, 1)
			this.filesPreview.splice(index, 1)
		},
		checkFilesAttach(){
			if(this.files.length > 0){
				this.disabledAttachSelector = false
			}
			else{
				this.disabledAttachSelector = true
				this.attachFiles = false
			}
		},
		toBase64(file) {
			return new Promise((resolve) => {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function () {
					resolve(reader.result)
				};
				reader.onerror = function () {
					resolve(null)
				};
			})
		},
		preCreateTarea(){
			if(this.inCancelTime){
				clearInterval(this.cancelInterval)
				this.confirmButtonText = 'Confirmar'
				this.inCancelTime = false
				return null
			}
			if(this.newTaskForm.description === '' || !this.newTaskForm.description){
				const datas = {
					message: 'Por favor escribe una descripción. Este campo no puede estar vacio.',
					title: 'Error',
					created: new Date(),
					type: 'success',
					icon: 'mdi-alert-circle',
					color: '#fb8c00'
				}
				this.$store.globalStore.dispatch('addMessage', datas)
				return false
			}
			if(this.$refs.form.validate()){
				this.inCancelTime = true
				this.cancelTimer = 7
				this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
				this.cancelInterval = setInterval(() => {
					this.cancelTimer--
					this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
					if(this.cancelTimer == 0){
						this.inCancelTime = false
						clearInterval(this.cancelInterval)
						this.createTarea()
					}
				}, 1000);
			}
		},
		async createTarea(){
			this.loadingData = true
			this.confirmButtonText = 'Confirmar'
			if(this.copy_to.length > 0){
				const emails_copy = this.copy_to.map((email) => {
					let emailData = null
					if(email.email){
						emailData = {email: email.email, name: email.name}
					}
					else{
						emailData = {email, name: ''}
					}
					return emailData
				})
				this.newTaskForm.copy_to = JSON.stringify(emails_copy)
			}
			const hasReminders = this.newTaskForm.reminder.length > 0
			this.newTaskForm.subtareas = JSON.stringify(this.subtareas)
			this.newTaskForm.participants = JSON.stringify(this.participants)
			this.newTaskForm.reminder = JSON.stringify(this.newTaskForm.reminder)
			this.newTaskForm.project_id = this.$store.projectsStore.state.selectedProject.id
			const formData = new FormData()
			Object.keys(this.newTaskForm).forEach((key) => {
				if(this.newTaskForm[key]){
					formData.append(key, this.newTaskForm[key])
				}
			})
			const biggerFiles = []
			this.files.forEach((file) => {
				if(file && file.size < 1000){
					formData.append('files[]', file)
				}
				else if(file){
					biggerFiles.push({
						type: 1,
						payload: null,
						description: this.newTaskForm.title,
						total: file.size,
						uuid: uuidv4(),
						file,
						attach_files: this.attachFiles,
						copy_to: this.newTaskForm.copy_to
					})
				}
			})
			const task = await create(formData)
			if(task.code === 200){
				if(biggerFiles.length > 0){
					biggerFiles.map(file => {
						file.item_id = task.task.id
						file.payload = {task_id: task.task.id, project_id: this.$store.projectsStore.state.selectedProject.id}
						this.$store.globalStore.commit('setFilesToUploadCenter', file)
					})
				}
				else {
					// Envío correo con adjuntos, función verifica que no se esté subiendo algún archivo aún
					const checkSendEmailData = {
						type: 1,
						item_id: task.task.id,
						copy_to: this.newTaskForm.copy_to,
						attach_files: this.attachFiles
					}
					this.$store.globalStore.dispatch('checkSendingEmail', checkSendEmailData)
				}

				this.getConsultasList()
				this.$store.projectsStore.dispatch('getDashboardInfo')
				this.closeModalSelf()
				this.setMessage(task.task, biggerFiles.length > 0)
				hasReminders && this.$store.notificationsStore.dispatch('getReminders')
			}
			this.loadingData = false
		},
		setMessage(task, filesToUC = false){
			let message = 'Haz click aquí para visualizarla.'
			if(filesToUC){
				message += ' Hay algunos archivos que se están cargando.'
			}
			const data = {
				message,
				title: 'Se ha creado la consulta/restricción ' + task.number,
				created: new Date(),
				type: 'success',
				icon: 'mdi-check-circle',
				color: '#3EBFA3',
				action: 'task',
				task_id: task.id
			}
			this.$store.globalStore.dispatch('addMessage', data)
		}
	}
};
</script>

<style scoped>
	.info-container{
		color: #606060;
	}
	.text-primary{
		color: #2D4B95;
	}
	.info-icon{
		background-color: #2D4B95;
		border-radius: 25px;
		padding: 3px;
		width: 35px;
		height: 35px;
	}
	.info-icon.small-icon{
		width: 25px;
		height: 25px;
	}
	.info-img{
		width: 100%;
		max-height: 50px;
		object-fit: contain;
	}
	.adjuntos-container{
		background-color: #EFEFEF;
		max-height: 95px;
		overflow-y: scroll;
	}
	.interaction-container{
		width: 100%;
		background-color: #E5E9F2;
		border-radius: 5px;
		position: relative;
		padding: 12px;
	}
	.user-avatar.small{
		width: 25px;
		height: 25px;
	}
	.light-blue-text{color: #5E85EF;}
	.interaction-container .icon-arrow{
		font-size: 80px;
		position: absolute;
		top: 20px;
		line-height: inherit;
	}
	.interaction-container.right .icon-arrow{
		left: -46px;
	}
	.interaction-container.left .icon-arrow{
		right: -46px;
	}
	.date-limit-input{
		max-width: 250px;
	}
	.preview-img-container{
		position: relative;
	}
	.preview-img{
		width: 50px;
		height: 50px;
		border-radius: 10px;
		object-fit: contain;
	}
	.button-preview-img{
		position: absolute;
		top: -1px;
		left: -1px;
		background-color: white;
	}
	/deep/ .remove-bottom-details .v-text-field__details{display: none;}
	/deep/ .remove-bottom-details .v-input__slot{margin-bottom: 0;}
/*  /deep/ .text-editor.tiptap-vuetify-editor .ProseMirror{
		margin: 0!important;
	}*/

	/* EXTRA FAVRIC CSS */
	.custom-chip {
		margin-top: 10px;
		display: flex;
		justify-content: end;
	}

</style>